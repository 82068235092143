import UserService from '../services/user.service';
const initialState = { user: null }

export const user = {
  namespaced: true,
  state: initialState,
  actions: {
  },
  getters: {
  },
  mutations: {
  },

};