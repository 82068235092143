<template>
  <div class="rn-product-area rn-section-gapTop">
    <div class="container">
      <div class="row mb--50 align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <h3
            class="title mb--0"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            Explore Scripts
          </h3>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
          <div class="view-more-btn text-start text-sm-end">
            <button
              v-if="false"
              class="discover-filter-button btn btn-primary"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Filter <i class="feather-filter" />
            </button>
          </div>
        </div>
      </div>

      <div
        id="collapseExample"
        class="collapse default-exp-wrapper"
      >
        <div class="inner">
          TODO ♡
        </div>
      </div>

      <div
        class="row g-5"
        data-sal="slide-up"
        data-sal-delay="150"
        data-sal-duration="800"
      >
        <template
          v-for="(vscript, index) in videoScriptSearched"
          :key="`product-item-${index}`"
        >
          <div
            v-if="index < 10"
            class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
          >
            <script-card
              :script-data="vscript"
              product-style-class="no-overlay"
            />
          </div>
        </template>
        <h3
          v-if="!videoScriptSearched.length"
          class="text-center"
        >
          No Script Found
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
    import ScriptCard from '@/components/card/ScriptCard.vue'
    import NiceSelect from '@/components/select/NiceSelect.vue'
    import RangeSlider from '@/components/range/RangeSlider.vue'
    import ScriptFilterMixin from '@/mixins/ScriptFilterMixin'

    export default {
        name: 'ExploreFilter',
        components: {RangeSlider, NiceSelect, ScriptCard},
        mixins: [ScriptFilterMixin],
        computed: {
            videoScriptSearched: function() {
              return this.$store.state.videoScriptSearched.list
            },
        },
        watch: {
            videoScriptSearched() {},
        },
    }
</script>