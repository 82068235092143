import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import vue3GoogleLogin from 'vue3-google-login'
import VueGtag from 'vue-gtag-next'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'animate.css'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import 'bootstrap'
import store from "./store";
import * as dotenv from 'dotenv'
dotenv.config()


const app = createApp(App)
app.use(VueSweetalert2);

// app.use(gAuthPlugin, { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID, scope: 'profile email', prompt: 'consent', fetch_basic_profile: true })
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(VueGtag, {
  config: {id: "UA-120301721-2"}
})
app.use(router).use(store).mount('#app')
