<template>
  <div>
    <!-- Start Footer Area -->
    <div class="rn-footer-one rn-section-gap bg-color--1 mt--100 mt_md--80 mt_sm--80">
      <div class="container">
        <div class="row gx-5">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="widget-content-wrapper">
              <div class="footer-left">
                <logo />
                <p class="rn-footer-describe">
                  단어! 외워! 대본집! 써서!
                </p>
              </div>
              <div class="widget-bottom mt--40 pt--40">
                <h6 class="title">
                  너의! 시간! 소중!
                </h6>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_mobile--40">
            <div class="footer-widget widget-quicklink">
              <h6 class="widget-title">
                Keep Script
              </h6>
              <ul class="footer-list-one">
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
            <div class="footer-widget widget-information">
              <h6 class="widget-title">
                Information
              </h6>
              <ul class="footer-list-one">
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
                <li class="single-list">
                  <router-link to="#">
                    Dummy
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
            <div class="footer-widget">
              <h6 class="widget-title">
                Recent Script Added
              </h6>
              <ul class="footer-recent-post">
                <li class="recent-post">
                  <div class="thumbnail">
                    <router-link to="#">
                      <img
                        :src="require(`@/assets/images/portfolio/portfolio-01.jpg`)"
                        alt="Product Images"
                      >
                    </router-link>
                  </div>
                  <div class="content">
                    <h6 class="title">
                      <router-link to="#">
                        Dummy
                      </router-link>
                    </h6>
                    <p>BACK when I was 20</p>
                    <span class="price">donation please</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Area -->

    <!-- Start Copyright Area -->
    <div class="copy-right-one ptb--20 bg-color--1">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="copyright-left">
              <span>©2022 Loopy. All rights reserved.</span>
              <ul class="privacy">
                <!-- <li>
                  <router-link to="/terms-condition">
                    Terms
                  </router-link>
                </li>
                <li>
                  <router-link to="/privacy-policy">
                    Privacy Policy
                  </router-link>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="copyright-right">
              <social-links />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Copyright Area -->

    <div class="mouse-cursor cursor-outer" />
    <div class="mouse-cursor cursor-inner" />

    <!-- Start Top To Bottom Area  -->
    <back-to-top @click="scrollTop" />
    <!-- End Top To Bottom Area  -->
  </div>
</template>

<script>
    import BackToTop from '@/components/layouts/footer/BackToTop'
    import Logo from "@/components/logo/Logo";
    import SocialLinks from "@/components/social/SocialLinks.vue";

    export default {
        name: 'Footer',
        components: {SocialLinks, Logo, BackToTop},
        methods: {
            scrollTop() {
                window.scrollTo(0, 0);
            }
        }
    }
</script>