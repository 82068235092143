<template>
  <layout>
    <breadcrumb
      title="Memorize"
      current="Memorize"
    />

    <div class="rn-blog-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5">
          <template
            v-for="(sentence, index) in sentenceList"
            :key="`sentence-${index}`"
          >
            <Transition>
              <div
                v-if="sentence.show"
                class="col-lg-4 col-md-6 col-12"
              >
                <FlipCard
                  :front-content="sentence.content"
                  :back-content="sentence.meaning"
                >
                  <template #front />
                  <template #back>
                    <div>
                      <button
                        class="btn btn-secondary btn-small"
                        @click="removeWord(sentence.content, true)"
                      >
                        O
                      </button>
                      <button
                        class="btn btn-secondary btn-small"
                        @click="removeWord(sentence.content, false)"
                      >
                        X
                      </button>
                    </div>
                  </template>
                </FlipCard>
              </div>
            </Transition>
          </template>
          
          <span v-if="isEmpty">
            외울 단어가 없습니다! 새로운 단어를 등록 해 보세요!
          </span>
          <span v-if="refreshNeeded">
            <button
              class="btn btn-primary"
              @click="$router.go();"
            >단어장 다시 불러오기 🔃</button>
          </span>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
    import FlipCard from '@/components/card/FlipCard.vue'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
    import Layout from '@/components/layouts/Layout'
    import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
    import WordService from '@/services/word.service';
    import SentenceService from '@/services/sentence.service';

    export default {
        name: 'Memorize',
        components: {FlipCard, Breadcrumb, Layout},
        mixins: [SalScrollAnimationMixin],
        data() {
          return {
            wordbook: [],
            sentenceList: [],
            isEmpty: true,
            refreshNeeded: false
          }
        },
        async mounted() {
          // TODO : wordbook store 만들어지면 다 없애자.
          const wordbook = await WordService.getWordbook()
          console.log(wordbook)
          this.wordbook = wordbook;
          wordbook.sentences.forEach(async sentence => {
            const _sentence = await SentenceService.getSentenceById(sentence.id)
            const showWord = sentence.showAt < Date.now() ? true : false;
            this.sentenceList.push({..._sentence, show:showWord})
            if(showWord === true) this.isEmpty = false;
          })
        },
        methods: {
            async removeWord(sentence, isCorrect) {
                try{
                  await WordService.updateWordbook(sentence, isCorrect ? "CORRECT_SENTENCE" : "WRONG_SENTENCE")
                  this.sentenceList = this.sentenceList.map(_sentence => {
                    if(_sentence.content === sentence)
                      _sentence.show = false;
                      return _sentence
                    }
                  )
                  if(this.sentenceList.findIndex(sentence => sentence.show === true) === -1) {
                    this.refreshNeeded = true;
                  }
                } catch (err) {
                  alert(err)
                }
            }
        }
    }
</script>

<style type='text/css' scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>