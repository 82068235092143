import axios from "axios";
import * as dotenv from 'dotenv'
import authHeader from './auth-header';
dotenv.config()

const API_URL = process.env.VUE_APP_BACKEND_URL;

class VideoScriptService {
  async create(scriptData) {
    let res = await axios.post(API_URL + "video-script", scriptData,  { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to create");
    }
    return res.data
  }
  async update(scriptData) {
    let res = await axios.put(API_URL + "video-script", scriptData,  { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to update");
    }
    return res.data
  }
  async getLists(page, limit, search) {
    const params = {};
    if(Number.isInteger(page)) params.page = page;
    if(Number.isInteger(limit)) params.limit = limit;
    if(search) params.search = search;
    let res = await axios.get(API_URL + "video-script", { headers: authHeader(), params });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find video scripts");
    } else if (res.data) {
      return res.data
    }
  }
  // IVideoScript {
  //   name: {
  //     title: string;
  //     subTitle: string;
  //   };
  //   script: string[];
  // }
  async get(id) {
    let res = await axios.get(API_URL + "video-script/"+id, { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find video script");
    } else if (res.data) {
      return res.data
    }
  }
}

export default new VideoScriptService();


 



                    