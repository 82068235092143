import axios from "axios";
import * as dotenv from 'dotenv'
import authHeader from './auth-header';
dotenv.config()

const API_URL = process.env.VUE_APP_BACKEND_URL;

class AuthService {
  async login(google_access_token, provider) {
    let res = await axios.post(process.env.VUE_APP_BACKEND_URL + "social-login-token", { provider, access_token : google_access_token });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to login");
    } else if (res.data.accessToken) {
      localStorage.setItem('accessToken', res.data.accessToken)
    }
    return res.data
  }
  async getMe() {
    let res = await axios.get(process.env.VUE_APP_BACKEND_URL + "me", { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
        throw Error("fail to find user");
    } else if (res.data) {
        localStorage.setItem('user', JSON.stringify(res.data))
        return res.data
    }
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
  }
}

export default new AuthService();


 



                    