import VideoScriptService from '@/services/video-script.service';

export default {
    data() {
        return {
        }
    },
    methods: {
        changeSearch(query) {
            const res = VideoScriptService.getLists(1, 100, query)
            this.$store.state.videoScriptSearched.list = res.data;
        },
    },
    async mounted() {
    }
}