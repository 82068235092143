<template>
  <layout>
    <!-- Start Banner Area -->
    <div class="slider-one rn-section-gapTop">
      <div
        v-if="!loggedIn"
        class="container"
      >
        <div class="row row-reverce-sm align-items-center">
          <div class="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
            <h2
              class="title"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Remember your memories from scripts.
            </h2>
            <p
              class="slide-disc"
              data-sal-delay="300"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Beta version. Feedback welcome :)
            </p>
            <div class="button-group">
              <router-link
                class="btn btn-large btn-primary"
                to="/about"
                data-sal-delay="400"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                여기 뭐 하는 곳인지 알아보기
              </router-link>
              <router-link
                class="btn btn-large btn-primary-alta"
                to="/"
                data-sal-delay="500"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                Dummy Button
              </router-link>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
            <div class="slider-thumbnail">
              <img
                :src="require(`@/assets/images/slider/mental-health.png`)"
                alt="Slider Images"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->

    <!-- Explore Product Area Start -->
    <explore-filter />
    <!-- Explore Product Area End -->

    <share-modal />

    <report-modal />
  </layout>
</template>

<script>
  import Layout from '../../components/layouts/Layout.vue'
  import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
  import ShareModal from '@/components/modal/ShareModal.vue'
  import ReportModal from '@/components/modal/ReportModal.vue'
  import ExploreFilter from '@/components/explore/ExploreFilter.vue'
  import VideoScriptService from '@/services/video-script.service';

  export default {
    name: 'HomePageOne',
    components: {
      ExploreFilter,
      ReportModal,
      ShareModal,
      Layout
    },
    mixins: [SalScrollAnimationMixin],
    computed: {
        loggedIn: function() {
            return this.$store.state.auth.status.loggedIn
        },
        searchKeyword: function() {
          return this.$route.query.search;
        }
    },
    watch: {
        loggedIn() {},
        async searchKeyword() {
          if(this.$route.query.search) {
            this.$store.dispatch("videoScriptSearched/search", {search: this.$route.query.search}).then(
              () => {}, (error) => {alert(error)}
            )
          } else {
            const res = await VideoScriptService.getLists(1, 10)
            this.$store.state.videoScriptSearched.list = res.data;
          }
        },
    },
    async mounted() {
      const res = await VideoScriptService.getLists(1, 10)
      this.$store.state.videoScriptSearched.list = res.data;
    },
  }
</script>