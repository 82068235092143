<template>
  <div
    id="createWordModal"
    class="rn-popup-modal report-modal-wrapper modal fade"
    tabindex="-1"
    aria-hidden="true"
  >
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      <i class="feather-x" />
    </button>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content report-content-wrapper">
        <div class="modal-header report-modal-header">
          <h5 class="modal-title">
            Add new word for everyone!<br>
            모두가 공유하는 단어장입니다. 예쁘게 잘 적어주세욥.. ♡
          </h5>
        </div>
        <div class="modal-body">
          <p>
            {{ sentence }}
          </p>
          <div class="report-form-box">
            <h6 class="title">
              Question (word / front)
            </h6>
            <textarea
              v-model="word.word"
              name="word"
              placeholder="단어 혹은 질문"
              @change="changeWordHandler"
            />
            <h6 class="title">
              Answer (meaning / back)
            </h6>
            <textarea
              v-model="word.meaning"
              name="meaning"
              placeholder="단어의 뜻 혹은 질문의 대답. 미리 등록된 단어가 있으면 자동 완성 됩니다."
            />
            <div class="report-button">
              <button
                type="button"
                class="btn btn-primary mr--10 w-auto"
                @click="createWordHandler"
              >
                Create
              </button>
              <button
                type="button"
                class="btn btn-primary-alta w-auto"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import WordService from '@/services/word.service';
  import SentenceService from '@/services/sentence.service';
  export default {
    props: {
      sentence: {
        type: String,
        default: ""
      },
    },
    data() {
      return {
        word: {
          word: "",
          meaning: ""
        }
      }
    },
    methods: {
      async createWordHandler() {
        try {
          await WordService.create(this.word)
          await SentenceService.updateSentence(this.sentence, this.word.word, 'ADD')
          alert("단어 등록 완료!")
          this.word.word = ''
          this.word.meaning = ''
        } catch (err) {
          alert(err);
        }
      },
      async changeWordHandler(event){
        const word = await WordService.getWord(event.target.value)
        if(word.meaning !== '') {
          this.word.meaning = word.meaning
        }
      }
    },
  }
</script>

