<template>
  <div class="rn-creators-area right-fix-notice creators">
    <div class="h--100">
      <div
        class="notice-heading"
        style="min-height: 60px;height: auto;flex-direction: column;padding-top: 10px;padding-bottom: 10px;"
      >
        <div style="display: flex;">
          <button
            type="button"
            class="btn btn-primary-alta"
            :disabled="wordbook.sentences.findIndex(myWord => myWord.id === sentenceId) !== -1"
            @click="addSentence(sentence)"
          >
            문장 저장하기
          </button>
          <button
            type="button"
            class="btn btn-primary-alta"
            data-bs-toggle="modal"
            data-bs-target="#createWordModal"
          >
            새 단어 등록
          </button>
        </div>
        <h4>{{ sentence }}</h4>
      </div>
    </div>
    <div class="rn-notification-wrapper creators">
      <button
        class="btn btn-primary-alta w-100"
        @click="refreshWords()"
      >
        refresh
      </button>
      <div
        v-for="(word, index) in wordList"
        :key="`word-${index}`"
        class="top-seller-inner-one"
      >
        <div class="top-seller-wrapper">
          <div class="top-seller-content">
            <h6 class="name">
              {{ word.word }}
            </h6>
            <span class="count-number">
              {{ word.meaning }}
            </span>
          </div>
        </div>
        <div>
          <button
            class="btn btn-primary-alta"
            :disabled="wordbook.words.findIndex(myWord => myWord.content === word.word) !== -1"
            @click="addWord(word.word)"
          >
            {{ wordbook.words.findIndex(myWord => myWord.content === word.word) === -1 ? 'Add' : 'Added' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SentenceService from '@/services/sentence.service';
  import WordService from '@/services/word.service';
  export default {
    name: 'WordListSidebar',
    components: {},
    props: {
      sentence: {
        type: String,
        default: ""
      },
    },
    data() {
      return {
        wordList: [],
        // TODO : store에다가 저장하는게 훨 나은거 나도 안다....
        wordbook: {
          words: [],
          sentences: [],
        },
        sentenceId: {
          type: Number,
          default: 0
        }
      }
    },
    async mounted() {
    },
    methods: {
      async refreshWords() {
        try {
          const sentence = await SentenceService.getSentence(this.sentence)
          this.sentenceId = sentence._id;
          const wordbook = await WordService.getWordbook()
          console.log(wordbook)
          this.wordbook = wordbook;
          this.wordList = []
          sentence.words.forEach(async word => {
            const _word = await WordService.getWord(word)
            this.wordList.push(_word)
          })
        } catch(err) {
          alert(err)
        }
      },
      async addWord(word) {
        try {
          const wordbook = await WordService.updateWordbook(word, 'ADD_WORD')
          console.log(wordbook)
          this.wordbook = wordbook
        } catch (err) {
          alert(err)
        }
      },
      async addSentence(sentence) {
        try {
          const wordbook = await WordService.updateWordbook(sentence, 'ADD_SENTENCE')
          console.log(wordbook)
          this.wordbook = wordbook
        } catch (err) {
          alert(err)
        }
      }
    }
  }
</script>