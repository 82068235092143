import axios from "axios";
import * as dotenv from 'dotenv'
import authHeader from './auth-header';
dotenv.config()

const API_URL = process.env.VUE_APP_BACKEND_URL;

class WordService {
  // create로 이미 있는 단어에 대해 호출하면 update 됨.
  async create(wordData) {
    let res = await axios.post(API_URL + "word", wordData,  { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to create");
    }
    return res.data
  }
  // async getLists(page, limit, search) {
  //   const params = {};
  //   if(Number.isInteger(page)) params.page = page;
  //   if(Number.isInteger(limit)) params.limit = limit;
  //   if(search) params.search = search;
  //   let res = await axios.get(API_URL + "video-script", { headers: authHeader(), params });
  //   if (res.status != 200 && res.status != 201) {
  //     throw Error("fail to find video scripts");
  //   } else if (res.data) {
  //     return res.data
  //   }
  // }
  async getWord(word) {
    let res = await axios.get(API_URL + "word/word/"+word, { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find word");
    } else if (res.data) {
      return res.data
    }
  }
  async getWordbook() {
    let res = await axios.get(API_URL + "word/wordbook", { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find word");
    } else if (res.data) {
      return res.data
    }
  }
  async updateWordbook( content, updateType ) {
    const params = {content, updateType}
    let res = await axios.put(API_URL + "word/wordbook", params, { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to update wordbook");
    } else if (res.data) {
      return res.data
    }
  }
}

export default new WordService();


 



                    