import axios from "axios";
import * as dotenv from 'dotenv'
import authHeader from './auth-header';
import qs from 'qs'
dotenv.config()

const API_URL = process.env.VUE_APP_BACKEND_URL;

class SentenceService {
  async updateSentence( content, word, updateType ) {
    const params = {content, word, updateType}
    console.log(params)
    let res = await axios.put(API_URL + "sentence", params, { headers: authHeader() });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to update sentence");
    } else if (res.data) {
      return res.data
    }
  }
  // IVideoScript {
  //   name: {
  //     title: string;
  //     subTitle: string;
  //   };
  //   script: string[];
  // }
  async getSentence(content) {
    let res = await axios.get(API_URL + "sentence", { headers: authHeader(), params: {content} });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find word");
    } else if (res.data) {
      return res.data
    }
  }
  async getSentenceById(id) {
    let res = await axios.get(API_URL + "sentence", { headers: authHeader(), params: {id} });
    if (res.status != 200 && res.status != 201) {
      throw Error("fail to find word");
    } else if (res.data) {
      return res.data
    }
  }
}

export default new SentenceService();


 



                    