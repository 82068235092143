export default function authHeader() {
    let accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return { Authorization: 'Bearer ' + accessToken };
        // for Node.js Express back-end will see.
        // return { 'x-access-token': accessToken };
    } else {
        return {};
    }
}