<template>
  <ul class="mainmenu">
    <li
      v-for="(item, index) in navItems"
      :key="`nav-item-${index}`"
      :class="[item.hasDropdown ? 'has-droupdown has-menu-child-item' : '', item.megamenu ? 'with-megamenu' : '']"
    >
      <router-link
        v-if="!item.forAdmin || isAdmin"
        :to="item.link"
        class="nav-link its_new"
      >
        {{ item.title }}
      </router-link>
      <ul
        v-if="item.hasDropdown && (!item.forAdmin || isAdmin)"
        class="submenu"
      >
        <li
          v-for="(subMenuItem, subMenuIndex) in item.dropdownItems"
          :key="`sub-menu-item-${subMenuIndex}`"
        >
          <router-link
            :to="subMenuItem.link"
            :class="subMenuItem.class"
          >
            {{ subMenuItem.title }}
            <i
              v-if="subMenuItem.class !== 'live-expo'"
              :class="`feather-${subMenuItem.icon}`"
            />
          </router-link>
        </li>
      </ul>
      <div
        v-if="item.megamenu && (!item.forAdmin || isAdmin)"
        class="rn-megamenu"
      >
        <div class="wrapper">
          <div class="row row--0">
            <div
              v-for="(megamenuItem, megamenuIndex) in item.megaMenuItems"
              :key="`mega-menu-item-${megamenuIndex}`"
              class="col-lg-3 single-mega-item"
            >
              <ul class="mega-menu-item">
                <li
                  v-for="(megamenuSubItem, megamenuSubIndex) in megamenuItem.items"
                  :key="`mega-submenu-item-${megamenuSubIndex}`"
                >
                  <router-link :to="megamenuSubItem.link">
                    {{ megamenuSubItem.title }} <i :class="`feather-${megamenuSubItem.icon}`" />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import _ from 'lodash';
export default {
    name: 'Nav',
    data() {
        return {
            navItems: [
                {
                    link: '/about',
                    title: 'About',
                    hasDropdown: false,
                    megamenu: false,
                    forAdmin: false,
                },
                {
                    link: '#',
                    title: 'Scripts',
                    hasDropdown: true,
                    megamenu: false,
                    dropdownItems: [
                        {link: '/?search=Modern Family', title: 'Modern Family', icon: 'tv'},
                        {link: '/?search=THE BIG BANG THEORY', title: 'The Big Bang Theory', icon: 'tv'},
                    ],
                    forAdmin: false,
                },
                {
                    link: '#',
                    title: 'Memorize',
                    hasDropdown: true,
                    megamenu: false,
                    dropdownItems: [
                        {link: '/memorize/word', title: 'Word', icon: 'bookmark'},
                        {link: '/memorize/sentence', title: 'Sentence', icon: 'bookmark'},
                    ],
                    forAdmin: false,
                },
                {
                  link: '/create',
                  title: 'New Script',
                  hasDropdown: false,
                  megamenu: false,
                  forAdmin: true,
                }
            ]
        }
    },
    computed: {
        isAdmin: function() {
          if(this.$store.state.auth.user) return _.includes(this.$store.state.auth.user.role, 'admin');
          else return false;
        }
    },
    watch: {
        isAdmin(value) {}
    },
}
</script>