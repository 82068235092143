<template>
  <div :class="`product-style-one ${productStyleClass}`">
    <div class="card-thumbnail">
      <router-link :to="`/script/${scriptData._id}`">
        <img
          :src="scriptData.img"
          :alt="scriptData.name.title"
          @load="$emit('handleImageLoad')"
        >
      </router-link>
      <countdown
        v-if="scriptData.countdown"
        :date="scriptData.countdown.date"
      />
    </div>
    <div class="product-share-wrapper">
      <div class="profile-share" />
      <div class="share-btn share-btn-activation dropdown">
        <button
          class="icon"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Not working
          <i class="feather-more-horizontal" />
        </button>
        <div class="share-btn-setting dropdown-menu dropdown-menu-end">
          <button
            type="button"
            class="btn-setting-text share-text"
            data-bs-toggle="modal"
            data-bs-target="#shareModal"
          >
            Share
          </button>
          <button
            type="button"
            class="btn-setting-text report-text"
            data-bs-toggle="modal"
            data-bs-target="#reportModal"
          >
            Report
          </button>
        </div>
      </div>
    </div>
    <router-link :to="`/script/${scriptData._id}`">
      <span class="product-name">{{ scriptData.name.title }}</span>
    </router-link>
    <span class="latest-bid">{{ scriptData.name.subTitle }}</span>
    <div class="bid-react-area">
      <div class="react-area">
        <i class="feather-heart" />
        <span class="number">{{ scriptData.reacted }}Not working now XD</span>
      </div>
    </div>
  </div>
</template>

<script>
    import Countdown from '@/components/product/Countdown';

    export default {
        name: 'ProductCard',
        components: {Countdown},
        props: {
            scriptData: {},
            productStyleClass: {
                type: String,
                default: ""
            },
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        }
    }
</script>