<template>
  <layout>
    <breadcrumb
      title="Script Details"
      current="Script Details"
    />

    <!-- Start product details area -->
    <div class="product-details-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5">
          <!-- Product image area Start -->
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="product-tab-wrapper">
              <div class="rn-pd-content-area">
                <div class="pd-title-area">
                  <h4 class="title">
                    {{ scriptInfo.name.title }}
                  </h4>
                  <div class="pd-react-area">
                    <div class="heart-count">
                      <i class="feather-heart" />
                      <span>{{ scriptInfo.reacted }}</span>
                    </div>
                    <div class="count">
                      <div class="share-btn share-btn-activation dropdown">
                        <button
                          class="icon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          not working XD
                          <i class="feather-more-horizontal" />
                        </button>
                        <div class="share-btn-setting dropdown-menu dropdown-menu-end">
                          <button
                            type="button"
                            class="btn-setting-text share-text"
                            data-bs-toggle="modal"
                            data-bs-target="#shareModal"
                          >
                            Share
                          </button>
                          <button
                            type="button"
                            class="btn-setting-text report-text"
                            data-bs-toggle="modal"
                            data-bs-target="#reportModal"
                          >
                            Report
                          </button>
                          <router-link 
                            v-if="isAdmin"
                            :to="`/update/${id}`"
                            class="btn-setting-text report-text"
                          >
                            Edit
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 class="title-name">
                  {{ scriptInfo.name.subTitle }}
                </h6>
              </div>
              <div class="pd-tab-inner">
                <div
                  class="right-fix-notice rn-notification-area col-sm-6"
                  style="flex: 0 0 auto; width: 50%;"
                >
                  <div
                    id="v-pills-tab"
                    class="nav rn-pd-nav rn-pd-rt-content nav-pills"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      v-for="(scriptSentence, index) in scriptInfo.script"
                      :id="`v-pills-${index}-tab`"
                      :key="`small-thumbnail-${index}`"
                      :class="[`nav-link`, {'active': index === activeTabIndex}]"
                      data-bs-toggle="pill"
                      :data-bs-target="`#v-pills-home-${index}`"
                      type="button"
                      role="tab"
                      :aria-controls="`v-pills-home-${index}`"
                      :aria-selected="index === activeTabIndex ? 'true' : 'false'"
                      @click="clickSentence(scriptSentence, index)"
                    >
                      <span class="rn-pd-sm-thumbnail">
                        {{ scriptSentence }}
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  id="v-pills-tabContent"
                  class="tab-content rn-pd-content"
                >
                  <div
                    v-for="(scriptSentence, index) in scriptInfo.script"
                    :id="`v-pills-home-${index}`"
                    :key="`large-thumbnail-${index}`"
                    :class="[`tab-pane fade`, {'show active': index === activeTabIndex}]"
                    role="tabpanel"
                    :aria-labelledby="`v-pills-${index}-tab`"
                  >
                    <word-list-sidebar 
                      :ref="`wordListSidebar`"
                      :sentence="scriptSentence"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End product details area -->
        
    <share-modal />
        
    <report-modal />

    <placebid-modal />
    
    <!-- TODO : Modal 이 거지같은새끼들 다 teleport같은걸로 정리하기. 귀찮음이 날 다 이겨버렸다. -->
    <create-word-modal :sentence="sentenceSelected" />
  </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout.vue'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import ProductCard from '@/components/product/ProductCard.vue'
    import ShareModal from '@/components/modal/ShareModal.vue'
    import ReportModal from '@/components/modal/ReportModal.vue'
    import Countdown from '@/components/product/Countdown.vue'
    import PlacebidModal from '@/components/modal/PlacebidModal.vue'
    import CreateWordModal from '@/components/modal/CreateWordModal.vue'
    import WordListSidebar from '@/components/word/WordListSidebar.vue'

    import ProductMixin from "@/mixins/ProductMixin";

    import VideoScriptService from '../services/video-script.service';
    import _ from 'lodash'

    export default {
        name: 'ProductDetails',
        components: {PlacebidModal, Countdown, ReportModal, ShareModal, ProductCard, Breadcrumb, Layout, WordListSidebar, CreateWordModal},
        mixins: [ProductMixin],
        data() {
            return {
                id: this.$route.params.id,
                scriptInfo: {name : {}},
                activeTabIndex: 0,
                sentenceSelected: "",
                isAdmin: _.includes(this.$store.state.auth.user?.role, 'admin'),
            }
        },
        watch: {
            '$route.params.id': function (val) {
                this.id = val;
                if(this.id) this.getScriptInfo(this.id);
            }
        },
        created() {
            this.getScriptInfo(this.id);
        },
        methods: {
            async getScriptInfo(scriptId) {
                this.scriptInfo = await VideoScriptService.get(scriptId);
                this.sentenceSelected = this.scriptInfo.script[0]
            },
            clickSentence(scriptSentence, index){
              this.sentenceSelected = scriptSentence;
              // this.$refs.wordListSidebar1[0].refreshWords()
              // console.log(`wordListSidebar`+index)
              this.$refs[`wordListSidebar`][index].refreshWords();
            }
        },
    }
</script>