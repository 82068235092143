import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module";
import { wordbook } from "./wordbook.module";
import { videoScriptSearched } from "./videoScriptSearched.module"

const store = createStore({
  modules: {
    auth, user, wordbook, videoScriptSearched
  },
});

export default store;