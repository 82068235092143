import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import WordService from '../services/word.service';
const accessToken = localStorage.getItem('accessToken');
const user = JSON.parse(localStorage.getItem('user'));
const initialState = accessToken && user
  ? { status: { loggedIn: true }, accessToken, user }
  : { status: { loggedIn: false }, accessToken: null, user:null };

export const wordbook = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit, dispatch }, payload) {
      return AuthService.login(payload.accessToken, payload.provider).then(
        accessToken => {
          commit('loginSuccess', accessToken);
          // 이게 안티패턴일 것 같기도 하다.
          return dispatch('getMe');
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    getMe({ commit }) {
      return AuthService.getMe().then(
        user => {
          commit('getMeSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('getMeFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  getters: {
    me(state, getters) {
      return state.user
    },
  },
  mutations: {
    loginSuccess(state, accessToken) {
      state.status.loggedIn = true;
      state.accessToken = accessToken;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.accessToken = null;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.accessToken = null;
      state.user = null;
    },
    getMeSuccess(state, user) {
      state.user = user;
    },
    getMeFailure(state) {
      state.status.loggedIn = false;
      state.accessToken = null;
      state.user = null;
    },
  }
};