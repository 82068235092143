import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import WordService from '../services/word.service';
import VideoScriptService from '@/services/video-script.service';
const accessToken = localStorage.getItem('accessToken');
const user = JSON.parse(localStorage.getItem('user'));
const initialState = {list: []};

export const videoScriptSearched = {
  namespaced: true,
  state: initialState,
  actions: {
    search({ commit }, payload) {
      return VideoScriptService.getLists(1, 100, payload.search).then(
        scriptList => {
          commit('listChanged', scriptList.data);
          return Promise.resolve(scriptList.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  getters: {
  },
  mutations: {
    listChanged(state, newList) {
      state.list = newList;
    }
  }
};