<template>
  <header class="rn-header haeder-default header--sticky">
    <div class="container">
      <div class="header-inner">
        <div class="header-left">
          <logo />
          <div class="mainmenu-wrapper">
            <nav class="mainmenu-nav d-none d-xl-block">
              <Nav />
            </nav>
          </div>
        </div>
        <div class="header-right">
          <div class="setting-option d-none d-lg-block">
            <form
              class="search-form-wrapper"
              @submit.prevent="searchScript()"
            >
              <input
                v-model="searchInput"
                type="search"
                placeholder="Search Here"
                aria-label="Search"
              >
              <div class="search-icon">
                <button><i class="feather-search" /></button>
              </div>
            </form>
          </div>
          <div class="setting-option rn-icon-list d-block d-lg-none">
            <div class="icon-box search-mobile-icon">
              <button
                @click.prevent="
                  AppFunctions.toggleClass('.large-mobile-blog-search', 'active'),
                  isMobileSearchActive = !isMobileSearchActive
                "
              >
                <i :class="isMobileSearchActive ? 'feather-x' : 'feather-search'" />
              </button>
            </div>
            <form
              id="header-search-1"
              action="#"
              method="GET"
              class="large-mobile-blog-search"
              @submit.prevent="searchScript()"
            >
              <div class="rn-search-mobile form-group">
                <button
                  type="submit"
                  class="search-button"
                >
                  <i class="feather-search" />
                </button>
                <input
                  v-model="searchInput"
                  type="text"
                  placeholder="Search ..."
                >
              </div>
            </form>
          </div>
          <div
            v-if="!loggedIn"
            id="rbt-site-header"
            class="setting-option header-btn rbt-site-header flex-shrink-0"
          >
            <div class="icon-box">
              <router-link
                id="connectbtn"
                class="btn btn-primary-alta btn-small"
                to="/login"
              >
                Login
              </router-link>
            </div>
          </div>
          <!-- 
            알림 나중에 만들거면 그때 ㄱㄱ
            <div class="setting-option rn-icon-list notification-badge">
            <div class="icon-box">
              <router-link to="/activity">
                <i class="feather-bell" /><span class="badge">6</span>
              </router-link>
            </div>
          </div> -->
          <div v-if="loggedIn">
            <div class="setting-option rn-icon-list user-account">
              <div class="icon-box">
                <router-link to="/">
                  <img
                    :src="user?.profileUrl"
                    alt="Images"
                  >
                </router-link>
                <div class="rn-dropdown">
                  <div class="rn-inner-top">
                    <h4 class="title">
                      <router-link to="/">
                        {{ user?.name }}
                      </router-link>
                    </h4>
                    <span>
                      <router-link to="#">DWYWD</router-link>
                    </span>
                  </div>
                  <div class="rn-product-inner">
                    <ul class="product-list">
                      <li class="single-product-list">
                        <div class="thumbnail">
                          <router-link to="/">
                            <img
                              :src="require(`@/assets/images/icons/meta.svg`)"
                              alt="Point Images"
                            >
                          </router-link>
                        </div>
                        <div class="content">
                          <h6 class="title">
                            <router-link to="/">
                              Point
                            </router-link>
                          </h6>
                          <span class="price">{{ user?.points }}</span>
                        </div>
                        <div class="button" />
                      </li>
                    </ul>
                  </div>
                  <div class="add-fund-button mt--20 pb--20">
                    <router-link
                      class="btn btn-primary-alta w-100"
                      to="/"
                    >
                      (TODO) Add NFT thing here
                    </router-link>
                  </div>
                  <ul class="list-inner">
                    <li>
                      <router-link to="/">
                        (TODO) My Profile
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/updatecards">
                        Memory cards
                      </router-link>
                    </li>
                    <li>
                      <button
                        class="btn btn-primary-alta mt--20 w-100"
                        @click="logout()"
                      >
                        Sign Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="setting-option mobile-menu-bar d-block d-xl-none">
            <div class="hamberger">
              <button
                class="hamberger-button"
                @click="AppFunctions.addClass('.popup-mobile-menu', 'active')"
              >
                <i class="feather-menu" />
              </button>
            </div>
          </div>
          <div class="my_switcher setting-option">
            <ul>
              <li>
                <a
                  href="#"
                  data-theme="light"
                  class="setColor light"
                  @click.prevent="AppFunctions.addClass('body', 'active-light-mode'), AppFunctions.removeClass('body', 'active-dark-mode')"
                >
                  <img
                    class="sun-image"
                    :src="require(`@/assets/images/icons/sun-01.svg`)"
                    alt="Sun images"
                  >
                </a>
              </li>
              <li>
                <a
                  href="#"
                  data-theme="dark"
                  class="setColor dark"
                  @click.prevent="AppFunctions.addClass('body', 'active-dark-mode'), AppFunctions.removeClass('body', 'active-light-mode')"
                >
                  <img
                    class="Victor Image"
                    :src="require(`@/assets/images/icons/vector.svg`)"
                    alt="Vector Images"
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
    import Nav from './Nav.vue';
    import AppFunctions from '../../../helpers/AppFunctions';
    import Logo from "@/components/logo/Logo.vue";

    export default {
        name: 'Header',
        components: {Logo, Nav},
        mixins: [],
        data() {
            return {
                AppFunctions,
                isMobileSearchActive: false,
                searchInput: ""
            }
        },
        computed: {
            loggedIn: function() {
                return this.$store.state.auth.status.loggedIn
            },
            user: function() {
                return this.$store.state.auth.user
            }
        },
        watch: {
            loggedIn() {},
            user() {}
        },
        mounted() {
            const header = document.querySelector('.header--sticky');
            const setStickyHeader = () => {
                const scrollPos = window.scrollY;
                if (scrollPos > 50) {
                    header.classList.add('sticky');
                } else {
                    header.classList.remove('sticky');
                }
            }
            window.addEventListener('scroll', setStickyHeader);
        },
        methods : {
            logout() {
                this.$store.dispatch("auth/logout", {}).then(
                    () => {
                        this.$router.push("/");
                    }, (error) => {
                        this.message =
                            (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            searchScript() {
              this.$store.dispatch("videoScriptSearched/search", {search: this.searchInput}).then(
                () => {}, (error) => {alert(error)}
              )
            }
        }
    }
</script>