<template>
  <layout>
    <div class="rn-activity-area rn-section-gapTop">
      <div class="container">
        <div class="row mb--30">
          <h3 class="title">
            Manage your cards
          </h3>
          <h5>Total : {{ wordList.length + sentenceList.length }}</h5>
        </div>
        <div class="row g-6 activity-direction">
          <div class="col-lg-8 mb_dec--15">
            <div
              v-for="(word, index) in wordList"
              :key="`activity-${index}`"
              class="single-activity-wrapper"
            >
              <div class="inner">
                <div class="read-content">
                  <div class="content">
                    <h6 class="title">
                      {{ word.word }}
                      <button
                        class="btn btn-primary-alta ml--20"
                        @click="showAlert(word.word,'word')"
                      >
                        수정하기
                      </button>
                      <!-- <button class="btn btn-primary-alta ml--20">
                        단어장에서 빼기
                      </button> -->
                    </h6>
                    <p> {{ word.meaning }} </p>
                    <div class="time-maintane">
                      <div class="time data">
                        <i class="feather-clock me-1" />
                        <span>{{ Date.now() - word.showAt > 0 ? "지금 외워야 함." : Math.floor((word.showAt - Date.now()) / 60000 / 60) + "시간 후 외워야 함." }}</span>
                      </div>
                      <div class="user-area data">
                        <i class="feather-user me-1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="icone-area">
                  <i :class="word.icon" />
                </div>
              </div>
            </div>
            <div
              v-for="(sentence, index) in sentenceList"
              :key="`activity-${index}`"
              class="single-activity-wrapper"
            >
              <div class="inner">
                <div class="read-content">
                  <div class="content">
                    <h6 class="title">
                      {{ sentence.content }}
                      <button
                        class="btn btn-primary-alta ml--20"
                        @click="showAlert(sentence.content,'sentence')"
                      >
                        수정하기
                      </button>
                      <!-- <button class="btn btn-primary-alta ml--20">
                        단어장에서 빼기
                      </button> -->
                    </h6>
                    <p> {{ sentence.meaning }} </p>
                    <div class="time-maintane">
                      <div class="time data">
                        <i class="feather-clock me-1" />
                        <span>{{ Date.now() - sentence.showAt > 0 ? "지금 외워야 함." : Math.floor((sentence.showAt - Date.now()) / 60000 / 60) + "시간 후 외워야 함." }}</span>
                      </div>
                      <div class="user-area data">
                        <i class="feather-user me-1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="icone-area">
                  <i :class="sentence.icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import SentenceService from '@/services/sentence.service';
    import WordService from '@/services/word.service';

    export default {
        name: 'Activity',
        components: {Layout},
        data() {
          return {
            wordbook: [],
            wordList: [],
            sentenceList: [],
          }
        },
        async mounted() {
          // TODO : wordbook store 만들어지면 다 없애자.
          const wordbook = await WordService.getWordbook()
          this.wordbook = wordbook;
          wordbook.words.forEach(async word => {
            const _word = await WordService.getWord(word.content)
            const showWord = word.showAt < Date.now() ? true : false;
            this.wordList.push({..._word, show:showWord, showAt: word.showAt})
            
            console.log(this.wordList)
            if(showWord === true) this.isEmpty = false;
          })
          wordbook.sentences.forEach(async sentence => {
            const _sentence = await SentenceService.getSentenceById(sentence.id)
            const showWord = sentence.showAt < Date.now() ? true : false;
            this.sentenceList.push({..._sentence, show:showWord, showAt: sentence.showAt})
            if(showWord === true) this.isEmpty = false;
          })
        },
        methods: {
          showAlert(text, contentKind) {
            this.$swal.fire({
              title: text,
              text: '수정할 뜻을 적어주세요.',
              input: 'text',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: '수정하기',
              showLoaderOnConfirm: true,
              preConfirm: async (meaning) => {
                try {
                  if(contentKind === 'word')
                  {
                    await WordService.create({
                      word: text,
                      meaning: meaning
                    });
                    return {
                      word: text,
                      meaning: meaning
                    };
                  } else {
                    await SentenceService.updateSentence(
                      // 좀 괴물같긴 하지만 귀찮아서 일단 파라미터 수정 안하고 이렇게 두겠습니다 ㅠ
                      text, meaning, "EDIT"
                    );
                    return {
                      word: text,
                      meaning: meaning
                    };
                  }
                } catch (err) {
                  this.$swal.showValidationMessage(
                    `Request failed: ${err}`
                  )
                }
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.$swal.fire({
                  title: `수정이 완료 되었습니다. 확인하시려면 새로고침 해주세요.`,
                  imageUrl: ''
                })
              }
            })
          },
        }
    }
</script>