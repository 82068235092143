<template>
  <layout>
    <breadcrumb
      title="Keep Login"
      current="Keep Login"
    />

    <div class="login-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-12 col-md-12 ml_md--0 ml_sm--0 col-sm-12">
            <div class="social-share-media form-wrapper-one">
              <h6>Log in</h6>
              <p>Social Login</p>
              <p>{{ message }}</p>
              <button
                class="another-login login-facebook"
                @click="login"
              >
                <img
                  class="small-image"
                  :src="require(`@/assets/images/icons/google.png`)"
                  alt=""
                >
                <span>Log in with Google</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
    import Layout from "@/components/layouts/Layout";
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import { googleTokenLogin } from "vue3-google-login"
    import { computed } from "vue";
    import { useStore } from "vuex";

    export default {
        name: 'Login',
        components: {Breadcrumb, Layout},
        data() {
            return {message: ""}
        },
        mounted () {
            if(this.$store.state.auth.loggedIn) {
                this.$router.push("/");
            }
        },
        methods : {
            login() {
                googleTokenLogin().then((response) => {
                    this.$store.dispatch("auth/login", {accessToken : response.access_token, provider : 'google'}).then(
                        () => {
                            this.$router.push("/");
                        }, (error) => {
                            this.message =
                                (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                })
            }
        }
    }
</script>