import axios from 'axios';
import authHeader from './auth-header';

import * as dotenv from 'dotenv'
dotenv.config()

const API_URL = process.env.VUE_APP_BACKEND_URL;

class UserService {
//   getPublicContent() {
//     return axios.get(API_URL + 'all');
//   }

//   getUserBoard() {
//     return axios.get(API_URL + 'user', { headers: authHeader() });
//   }

//   getModeratorBoard() {
//     return axios.get(API_URL + 'mod', { headers: authHeader() });
//   }

//   getAdminBoard() {
//     return axios.get(API_URL + 'admin', { headers: authHeader() });
//   }
}

export default new UserService();