<template>
  <layout>
    <breadcrumb
      title="Crete a New File"
      current="Crete a New File"
    />

    <form
      class="create-area rn-section-gapTop"
      @submit.prevent=""
    >
      {{ message }}
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-12">
            <div class="form-wrapper-one">
              <form
                class="row"
                action="#"
              >
                <div class="col-md-12">
                  <div class="input-box pb--20">
                    <label
                      for="title"
                      class="form-label"
                    >Video Script Title</label>
                    <input
                      id="title"
                      v-model="name.title"
                      placeholder="e. g. `Digital Awesome Game`"
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-box pb--20">
                    <label
                      for="subTitle"
                      class="form-label"
                    >Video Script Subtitle</label>
                    <input
                      id="subTitle"
                      v-model="name.subTitle"
                      placeholder="e. g. `Digital Awesome Game`"
                    >
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-box pb--20">
                    <label
                      for="imgUrl"
                      class="form-label"
                    >Video Script img url</label>
                    <input
                      id="imgUrl"
                      v-model="img"
                      placeholder="e. g. `https://naver.com/aaa.jpg`"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box pb--20">
                    <label
                      for="scripts"
                      class="form-label"
                    >Video Scripts</label>
                    <textarea
                      id="scripts"
                      rows="3"
                      placeholder="e. g. “After purchasing the product you can get item...”"
                      @change="calcScripts"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="rn-notification-area right-fix-notice">
                    <label
                      for="scripts"
                      class="form-label"
                    >Video Script Preview</label>
                    <div class="rn-notification-wrapper">
                      <div
                        v-for="(script, index) in scriptList"
                        :key="`script-${index}`"
                        class="single-notice"
                      >
                        <div class="content-wrapper">
                          {{ script }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-xl-12 mt_lg--15 mt_md--15 mt_sm--15">
                  <div class="input-box">
                    <button
                      type="button"
                      class="btn btn-primary btn-large w-100"
                      :class="{'disabled': (isLoading)}"
                      @click="onSubmit"
                    >
                      Submit Item
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </form>
  </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
    import ProductCard from '@/components/product/ProductCard'
    import ShareModal from '@/components/modal/ShareModal'
    import ReportModal from '@/components/modal/ReportModal'
    import VideoScriptService from '@/services/video-script.service'

    export default {
        name: 'CreateScript',
        components: {ReportModal, ShareModal, ProductCard, Breadcrumb, Layout},
        data() {
            return {
                selectedImage: null,
                name: {
                    title: "",
                    subTitle: ""
                },
                img: "",
                scriptList: [],
                isLoading: false,
                message: ""
            }
        },
        methods: {
            onSubmit(e) {
              this.isLoading = true
              const reqData = {
                name: {
                  title: this.name.title,
                  subTitle: this.name.subTitle
                },
                img: this.img,
                script: this.scriptList
              }
              VideoScriptService.create(reqData).then(() => {
                this.$router.push("/");
              }, (error) => {
                this.message =
                  (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  error.message ||
                  error.toString();
                  this.isLoading = false;
              })
            },
            calcScripts(e) {
                this.scriptList = e.target.value.split('\n').filter(el => {
                  return el.trim() != ''
                })
            }
        }
    }
</script>