<template>
  <ul class="social-copyright">
    <li>
      <a
        href="https://www.facebook.com/sunwoong.lee.31"
        target="_blank"
      ><i class="feather-facebook" /></a>
    </li>
    <li>
      <a
        href="https://twitter.com/bartender_loopy/"
        target="_blank"
      ><i class="feather-twitter" /></a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/leesunwoong/"
        target="_blank"
      ><i class="feather-instagram" /></a>
    </li>
    <li>
      <a
        href="https://www.linkedin.com/"
        target="_blank"
      ><i class="feather-linkedin" /></a>
    </li>
    <li>
      <a
        href="https://www.gmail.com/"
        target="_blank"
      ><i class="feather-mail" /></a>
    </li>
  </ul>
</template>

<script>
    export default {
        name: 'SocialLinks'
    }
</script>