<template>
  <layout>
    <!-- Start Banner Area -->
    <div class="rn-about-banner-area rn-section-gapTop">
      <div class="container mb--30">
        <div class="row">
          <div class="col-12">
            <h2
              class="title about-title-m"
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="150"
            >
              Save your time. <br>Life is way short.
            </h2>
          </div>
        </div>
      </div>
      <div class="container-fluid about-fluidimg ">
        <div class="row">
          <div class="img-wrapper">
            <div
              class="bg_image"
              style="background-image: url(/mood.jpg);"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6">
            <div class="h--100">
              <div class="rn-about-card mt_dec--50 widge-wrapper rbt-sticky-top-adjust">
                <div class="inner">
                  <h2
                    class="title"
                    data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="150"
                  >
                    Why I Made This
                  </h2>
                  <p
                    class="about-disc"
                    data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="150"
                  >
                    인생? 짧아.<br>
                    재미없는 거 하면서 시간 보내기 싫어.<br>
                    근데 영어 단어는 또 외워야 해.<br>
                    그래서 미드 보면서 단어 가성비 좋게 외우고 싶었어.
                  </p>
                  <router-link
                    to="/"
                    class="btn btn-primary-alta btn-large sal-animate mt--20"
                    data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="150"
                  >
                    내가 원하는 스크립트 있나 보기.
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="rn-about-card transparent-bg">
              <div class="inner">
                <h3
                  class="title"
                  data-sal="slide-up"
                  data-sal-duration="800"
                  data-sal-delay="150"
                >
                  Helping You <br>Memorize More Efficiently.
                </h3>
                <p
                  class="about-disc mb--0"
                  data-sal="slide-up"
                  data-sal-duration="800"
                  data-sal-delay="150"
                >
                  여기서 할 수 있는건 미드 대본에서 모르는 단어를<br>
                  미드 대본 보면서 바로 바로 단어장에 추가할 수 있게 해줘.<br>
                  게다가 개개인 맞춤형으로 망각곡선에 따라서<br>
                  너가 모르는 단어 모를 때 쯤 다시 상기시켜주는<br>
                  아무튼 생각없이 알아서 단어 추천 되게 만들었다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->

    <div class="rn-about-Quote-area rn-section-gapTop">
      <div class="container">
        <div class="row g-5 d-flex align-items-center">
          <div class="col-lg-6">
            <div class="rn-about-title-wrapper">
              <h3
                class="title"
                data-sal="slide-up"
                data-sal-duration="800"
                data-sal-delay="150"
              >
                예시를 봐줄래?
              </h3>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="rn-about-wrapper"
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="150"
            >
              <p>
                1. 미드 보면서 모르는 단어 생기면 등록하기/ <br>
              </p>              
              <img src="/aboutsample01.jpg">
            </div>
            <div
              class="rn-about-wrapper"
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="150"
            >
              <p>
                2. 여러 사람이 같이 등록 해 놓은 단어들 중에 모르는 거 Add 하기. <br>
              </p>              
              <img src="/aboutsample02.jpg">
            </div>
            <div
              class="rn-about-wrapper"
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="150"
            >
              <p>
                3. 적당히 모르는 단어 차면 Memorize 쪽으로 가기. <br>
              </p>
              <img src="/aboutsample03.jpg">
            </div>
            <div
              class="rn-about-wrapper"
              data-sal="slide-up"
              data-sal-duration="800"
              data-sal-delay="150"
            >
              <p>
                4. 단어카드 굴리듯이 외우면 O 아니면 X 누르기. <br>
                O 누르면 단어장에서 잠시 빠졌다가<br>
                AI가 알아서 너가 외워야 할 때 쯤 다시 Memorize에 추가 해 줌.<br>
              </p>              
              <img src="/aboutsample04.jpg">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Statistics Area -->
    <div class="rn-statistick-area rn-section-gapTop">
      <div class="container">
        <div class="row mb--30">
          <div class="col-12 text-center">
            <h3>여태까지의 통계들</h3>
            <h5>난 가성비가 중요해서 이런 짜치는건 아직 계산 안해봤어 미안ㅋ</h5>
          </div>
        </div>
        <div class="row g-5">
          <div class="offset-lg-2 col-lg-4 col-md-6">
            <div class="single-counter-up text-center">
              <Waypoint @change="onChange">
                <div
                  v-if="startCounter"
                  class="number counter-odomitter-active"
                >
                  <count-up
                    :start-val="0"
                    :end-val="1"
                    :duration="5"
                    class="d-inline-block"
                  />
                </div>
              </Waypoint>
              <div class="botton-title">
                저장된 단어 수
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-counter-up text-center">
              <Waypoint @change="onChange">
                <div
                  v-if="startCounter"
                  class="number counter-odomitter-active"
                >
                  <count-up
                    :start-val="0"
                    :end-val="1"
                    :duration="5"
                    class="d-inline-block"
                  />
                </div>
              </Waypoint>
              <div class="botton-title">
                총 스크립트 수
              </div>
            </div>
          </div>
          <div class="offset-lg-2 col-lg-4 col-md-6">
            <div class="single-counter-up text-center">
              <Waypoint @change="onChange">
                <div
                  v-if="startCounter"
                  class="number counter-odomitter-active"
                >
                  <count-up
                    :start-val="0"
                    :end-val="1"
                    :duration="5"
                    class="d-inline-block"
                  />
                </div>
              </Waypoint>
              <div class="botton-title">
                있어보이는 통계 뭐 넣지
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="single-counter-up text-center">
              <Waypoint @change="onChange">
                <div
                  v-if="startCounter"
                  class="number counter-odomitter-active up-plus"
                >
                  <count-up
                    :start-val="0"
                    :end-val="1"
                    :duration="5"
                    class="d-inline-block"
                  />
                </div>
              </Waypoint>
              <div class="botton-title">
                개발자 수
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Statistics Area -->

    <!-- Start CTA Area -->
    <div class="rn-callto-action rn-section-gapTop">
      <div class="container-fluid about-fluidimg-cta">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="bg_image bg-image-border"
              style="background-image: url(/mood.jpg);"
              data-black-overlay="7"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="call-to-action-wrapper">
                    <h3
                      data-sal="slide-up"
                      data-sal-duration="800"
                      data-sal-delay="150"
                    >
                      아무튼 미드같은거 보면서 <br>가성비 좋게 단어나 외우자.
                    </h3>
                    <p
                      data-sal="slide-up"
                      data-sal-duration="800"
                      data-sal-delay="150"
                    >
                      인생은 졸라게 아깝고, 노는게 세상에서 제일 조아.
                    </p>
                    <div
                      class="callto-action-btn-wrapper"
                      data-sal="slide-up"
                      data-sal-duration="800"
                      data-sal-delay="150"
                    >
                      <router-link
                        to="/"
                        class="btn btn-primary btn-large"
                      >
                        스크립트들 보러 가기
                      </router-link>
                      <router-link
                        to="/memorize"
                        class="btn btn-primary-alta btn-large"
                      >
                        내가 저장한 단어 보러 가기
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End CTA Area -->
  </layout>
</template>

<script>
    import CountUp from 'vue-countup-v3'
    import { Waypoint } from 'vue-waypoint'
    import Layout from "@/components/layouts/Layout";
    import BlogCard from "@/components/blog/BlogCard";
    import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
    import BlogMixin from "@/mixins/BlogMixin";

    export default {
        name: 'About',
        components: {BlogCard, Layout, CountUp, Waypoint},
        mixins: [SalScrollAnimationMixin, BlogMixin],
        data() {
            return {
                startCounter: false
            }
        },
        methods: {
            onChange(waypointState) {
                if (waypointState.going === 'IN') {
                    this.startCounter = true;
                }
            }
        }
    }
</script>